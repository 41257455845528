import React, { useState } from "react";

// import {HiOutlineBookOpen} from "react-icons/hi"
import Logo from "../assets/NewLogo.jpg";
import { RxCross1 } from "react-icons/rx";
import { IoReorderThree } from "react-icons/io5";

export const Nav = () => {
    let Links = [
        { name: "ABOUT US", link: "/About" },
        { name: "OUR TEAM", link: "/Team" },
        { name: "PROJECTS", link: "/Projects" },
        { name: "ACHIEVEMENTS", link: "/Achievements" },
        { name: "FAQs", link: "/faq" },
    ];
    let [open, setOpen] = useState(false);


  return (
    <div className="shadow-md w-full fixed top-0 left-0">
      <div className="md:flex items-center justify-between bg-black py-4 md:px-10 sm:px-7 ">
        {/* logo section */}
        <a href="/">
          <img src={Logo} alt="logo"  className="w-66 object-contain h-16 scale-75 md:scale-[] md:w-auto lg:scale-100"/>
        </a>
        {/* Menu icon */}
        <div
          onClick={() => setOpen(!open)}
          className="absolute right-8 top-8  cursor-pointer lg:hidden w-7 h-7"
        >
          {open ? (
            <RxCross1 color="white" size={32} />
          ) : (
            <IoReorderThree color="white" size={32} />
          )}
        </div>
        {/* link items */}
        <ul
          className={`lg:flex lg:items-center text-center lg:pb-0 pb-12 absolute lg:static bg-black md:z-auto z-[-1] left-0 w-full lg:w-auto transition-all duration-500 ease-in ${
            open ? "top-16 md:top-20" : "top-[-490px]"
          }`}
        >
          {Links.map((link) => (
            <li className="lg:ml-8 lg:my-0 my-7 whitespace-nowrap font-normal text-sm">
              <a
                href={link.link}
                className="text-white hover:text-hovernav duration-500"
              >
                {link.name}
              </a>
            </li>
          ))}
          <a href="/JoinUs">
          <button className="btn bg-yellownav hover:bg-yellow-300 mb-5 lg:mb-0 whitespace-nowrap text-white lg:ml-8 font-semibold px-3 py-1 rounded duration-500 md:static">
            JOIN US
          </button>
          </a>
          <br />
          <a href="/Donate">
          <button className="btn bg-yellownav hover:bg-yellow-300 text-white lg:ml-8 font-semibold px-3 py-1 rounded duration-500 md:static">
            DONATE
          </button>
          </a>
        </ul>
        {/* button */}
      </div>
    </div>
  );
};
