import React from 'react'

const Dropdown = () => {
  return (
    <div>
        <div class="grid grid-cols-1 gap-6 mt-7">
            <select id="countries" name='dropdown' class="block w-full px-4 py-2 mt-2 text-black bg-gray-50 border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring">
                <option selected>How did you find us?</option>
                <option value="Social Media">Social Media</option>
                <option value="Friend">Friend</option>
                <option value="Online">Online</option>
                <option value="Newspaper">Newspaper</option>
                <option value="Others">Others</option>
            </select>
        </div>
    </div>
  )
}

export default Dropdown