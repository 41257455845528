import React, { useEffect, useState } from "react";
import About from "../assets/About.webp";
import { AboutCard } from "../components/AboutCard";
import comm from "../assets/comm.png";
import cv from "../assets/cv.png";
import cert from "../assets/cert.png";
import net from "../assets/net.png";
import lead from "../assets/lead.png";
import pin from "../assets/pin.png";
import map from "../assets/map.png";
import lionsor from "../assets/lionsor.png";
import leoesor from "../assets/leoesor.png";
import { AboutTo } from "../components/AboutTo";

import Scroller from "../components/Scroller";
import { President } from "../components/President";

import { client } from "../lib/client";
import ScrollerTwo from "../components/ScrollerTwo";

import L from "../assets/L.jpeg";
import E from "../assets/E.jpeg";
import O from "../assets/O.jpeg";

export const AboutUs = () => {
  const [partner, setPartner] = useState([]);
  const [president, setPresident] = useState([]);
  const [len, setLen] = useState(0);
  useEffect(() => {
    client
      .fetch(
        `*[_type == "partner"]{
      images[]{
        asset -> {
          _id,
          url
        }
      },
    }`
      )
      .then((data) => {
        setPartner(data);
        if (data[0]?.images?.length > 0) {
          setLen(data[0].images.length);
        }
        console.log(data);
      })
      .catch(console.error);

    client
      .fetch(
        `*[_type == "president"]{
      id,
      name,
      tenure,
      description,
      image{
        asset -> {
          _id,
          url
        }
      }
    }
    | order(id asc)`
      )
      .then((data) => {
        setPresident(data);
        console.log(data);
      })
      .catch(console.error);
  }, []);

  return (

    <div className="">

      <div className="min-h-screen: w-full relative flex items-center justify-center">
        <img className="w-screen h-[90%] object-cover" src={About} alt="" />
      </div>

      <div className="flex flex-col items-center justify-between ">
        <div className=" pt-5 ">
          <h1 className=" text-5xl font-bold">OUR CLUB</h1>
        </div>
        <div className="py-8 px-10 text-lg text-center font-extralight">
          Lions Club International, the largest non-profit organization network with presence in more than 200 countries, founded in 1917 by Melvin Jones, a 38-year-old business leader. Lions International's service initiatives include vision care, diabetes awareness, hunger relief, and environmental sustainability and childhood cancer. The organization also supports youth development programs and provides disaster relief and emergency assistance when needed.
          <br />
          Leo Clubs are youth-led community service organizations that operate under the umbrella of Lions Clubs International. Leo Clubs provide young people aged 12 to 30 with opportunities to serve their communities, develop leadership skills, and network with other young people around the world through participation in various community initiatives. They are dependent on a Lions club to sponsor and initiate a Leo club. The Parent Lions Clubs International.

        </div>
        <div className="px-10 pb-10 text-lg text-center font-extralight">
          Leo Club Eternity Service is a unit of youth volunteers functioning under Leo District 321-A2. LEO CLUB ETERNITY SERVICE is dedicated towards serving those in need and making a strong impact in the world. Our motto is "We Serve, because we can" which embodies the notion of social service. The fundamental mission is to promote volunteerism, leadership, and service skills among the young population by giving them the opportunity and experience to support local and global initiatives that address social, environmental, economic issues.
          <br />
          Started off as Leo Club DreamTeam in 2020, The club recently revamped in 2023 and started its operations as Leo Club Eternity Service. Currently, We are an organization of 80+ volunteers reflecting diversity in terms of different colleges such as SRCC, NIFT, IPCW, IGNOU, HINDU, RLAC and AMITY etc and also in terms of the academic pursuits of the volunteers such as B.tech, M.B.B.S, Psychology, Political science, Commerce and so much more.
          <br />
          Currently, The Club has 5 Active Flagship Projects. Project Saathi: A Step Towards Animal Welfare; Project Ilaahi: Serving People with special needs; Project ShikshaVeer: Education for all; Project Seher: A step towards Environmental Conservation; and Project Seerat: I see you, I stand with you, a mental health initiative. We are always open to hearing out new ideas and are keen on starting new projects! In addition to our service projects, we also offer various leadership development opportunities, including training sessions, workshops, and conferences.
          We welcome anyone who shares the same passion to join us in our efforts. Thank you for taking the time to learn more about Leo Club Eternity Service. If you are interested in getting involved, please visit our website or contact us for more information.
          Together, we can make a difference and create a better world for all.


        </div>
      </div>
      <div className="flex justify-center translate-y-12 md:translate-y-16">
        <div className="bg-black rounded-2xl py-5 px-5 w-fit md:w-fit md:px-10 md:py-5">
          <h1 className="text-white font-medium text-2xl sm:text-3xl md:text-5xl mx-auto z-0 shadow-xl">
            LEO-LION STRUCTURE
          </h1>
        </div>
      </div>

      <div className="bg-yellowland h-fit flex items-center flex-col ">
        <div className="flex justify-center items-center flex-col -my-8 sm:my-24 scale-[0.45] xs:scale-50 sm:scale-[0.95] lg:scale-100">
          <div className="bg-white rounded-lg px-2 py-2 font-semibold w-[700px] flex items-center justify-between flex-col my-2 border-2 border-black">
            <h1 className="text-3xl"> LIONS CLUB INTERNATIONAL</h1>
            <h2 className="text-xl font-normal">OAK BROOK, ILLINOIS, USA</h2>
          </div>
          <div className="bg-white rounded-lg px-2 py-2 font-semibold w-[600px] flex items-center justify-between flex-col my-5 border-2 border-black">
            <h1 className="text-3xl"> ISAME</h1>
            <h2 className="text-xl font-normal">
              Constitutional Area 6 for India
            </h2>
          </div>
          <div className="bg-white rounded-lg px-2 py-2  font-semibold w-[500px] flex items-center justify-between flex-col my-5 border-2 border-black">
            <h1 className="text-3xl"> MULTIPLE DISTRICTS</h1>
            <h2 className="text-xl font-normal">Multiple District 321</h2>
          </div>
          <div className="bg-white rounded-lg px-2 py-2  font-semibold w-[400px] flex items-center justify-between flex-col my-5 border-2 border-black">
            <h1 className="text-3xl"> DISTRICTS</h1>
            <h2 className="text-xl font-normal">District 321 A2</h2>
          </div>
          <div className="bg-white rounded-lg px-2 py-2  font-semibold w-[300px] flex items-center justify-between flex-col my-5 border-4 border-black ">
            <h1 className="text-3xl"> LEO CLUB</h1>
            <h2 className="text-xl font-normal">Eternity Service</h2>
          </div>
        </div>
      </div>

      <div className="president ">
        <div className="title bg-black rounded-2xl py-5 px-5 md:px-10 md:py-5 w-fit font-medium text-2xl sm:text-3xl md:text-5xl mx-auto z-0 shadow-xl -translate-y-6 md:-translate-y-8 text-yellownav">
          PRESIDENT'S NOTE
        </div>
        <div className="note">
          {president.map((item, index) => (
            <div key={index}>
              <President {...item} />
            </div>
          ))}
        </div>
      </div>

      <div className="awards bg-[#f7d858] mt-8 h-fit pb-16">
        <div>
          <div className="bg-gray-100 title rounded-2xl py-5 px-8 md:px-10 md:py-5 w-fit font-medium text-2xl sm:text-3xl md:text-5xl mx-auto z-0 -translate-y-6 md:-translate-y-8 text-black shadow-2xl">
            VISION
          </div>
        </div>
        <div className="card grid grid-cols-1 sm:grid-cols-3 gap-2 mb">
          <AboutTo image={L} heading="Leadership" description="guiding and inspiring others towards a service and driving positive change " />
          <AboutTo image={E} heading="Experience" description="shaping perspectives, strengthening skills, encouraging innovation  and provides valuable insights for growth through action" />
          <AboutTo image={O} heading="Oppurtunity" description="enabling personal growth and developing service attitude by exploring new paths and unlocking potential through offering prospects" />
        </div>
      </div>

      <div className="flex justify-center -translate-y-12 ">
        <div className="bg-black rounded-2xl py-5 px-5 md:px-10 md:py-5 w-fit font-medium text-2xl sm:text-3xl md:text-5xl mx-auto z-0 shadow-xl translate-y-1 md:translate-y-1 ">
          <h1 className="text-white ">PERKS OF BEING A LEO</h1>
        </div>
      </div>

      <div className="grid grid-cols-1  md:flex md:flex-row md:justify-center md:gap-20 md:py-7">
        <div className="bg-black flex flex-row items-center w-fit h-fit my-5 mx-2 md:my-0 md:h-32 md:w-2/5 md:px-5 rounded-xl">
          <img
            className="w-[75px] h-[75px] md:w-24 md:h-24 ml-2 py-2"
            src={comm}
            alt=""
          />
          <p className="text-white ml-2 md:ml-5">
            Join the world's largest community service organization, enabling
            you to make a significant global impact
          </p>
        </div>
        <div className="bg-black flex flex-row items-center w-fit h-fit my-5 mx-2 md:my-0 md:h-32 md:w-2/5 md:px-5 rounded-xl">
          <img
            className="w-[75px] h-[75px] md:w-24 md:h-24 ml-2 py-2"
            src={map}
            alt=""
          />
          <p className="text-white ml-2 md:ml-5">
            Gain international recognition and exposure through participation in
            conferences and exchange programs
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 md:flex md:flex-row md:justify-center md:gap-20 md:py-7 ">
        <div className="bg-black flex flex-row items-center w-fit h-fit my-5 mx-2 md:my-0 md:h-32 md:w-2/5 md:px-5 rounded-xl ">
          <img
            className="w-[75px] h-[75px] md:w-24 md:h-24 ml-2 py-2"
            src={cv}
            alt=""
          />
          <p className="text-white ml-2 md:ml-5">
            Build and Enhance your CV, showcasing your dedication to community
            service and leadership skills
          </p>
        </div>
        <div className="bg-black flex flex-row items-center w-fit h-fit my-5 mx-2 md:my-0 md:h-32 md:w-2/5 md:px-5 rounded-xl">
          <img
            className="w-[75px] h-[75px] md:w-24 md:h-24 ml-2 py-2"
            src={cert}
            alt=""
          />
          <p className="text-white ml-2 md:ml-5">
            Receive certificates and potential letter of recommendations for
            exceptional performance
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 md:flex md:flex-row md:justify-center md:gap-20 md:py-7">
        <div className="bg-black flex flex-row items-center w-fit h-fit my-5 mx-2 md:my-0 md:h-32 md:w-2/5 md:px-5 rounded-xl">
          <img
            className="w-[75px] h-[75px] md:w-24 md:h-24 ml-2 py-2"
            src={net}
            alt=""
          />
          <p className="text-white ml-2 md:ml-5">
            Exceptional Networking Opportunities fostering personal growth
            through communication
          </p>
        </div>
        <div className="bg-black flex flex-row items-center w-fit h-fit my-5 mx-2 md:my-0 md:h-32 md:w-2/5 md:px-5 rounded-xl">
          <img
            className="w-[75px] h-[75px] md:w-24 md:h-24 ml-2 py-2"
            src={lead}
            alt=""
          />
          <p className="text-white ml-2 md:ml-5">
            Develop and refine leadership skills through various opportunities
            within the organization
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 md:flex md:flex-row md:justify-center md:gap-20 md:py-7 mb-20">
        <div className="bg-black flex flex-row items-center w-fit h-fit my-5 mx-2 md:my-0 md:h-32 md:w-2/5 md:px-5 rounded-xl">
          <img
            className="w-[75px] h-[75px] md:w-24 md:h-24 ml-2 py-2"
            src={pin}
            alt=""
          />
          <p className="text-white ml-2 md:ml-5">
            Earn prestigious Leo Pins and other accolades for your outstanding
            contributions to the community
          </p>
        </div>
        <div className="bg-black flex flex-row items-center w-fit h-fit my-5 mx-2 md:my-0 md:h-32 md:w-2/5 md:px-5 rounded-xl">
          <img
            className="w-[75px] h-[75px] md:w-24 md:h-24 ml-2 py-2"
            src={comm}
            alt=""
          />
          <p className="text-white ml-2 md:ml-5">
            Gain international recognition and exposure through participation in
            conferences and exchange programs
          </p>
        </div>
      </div>

      <div className="awards bg-[#f7d858] mt-8">
        <div>
          <div className="title text-white py-5 px-10 h-fit w-fit bg-black rounded-[20px] flex justify-center items-center font-bold text-xl sm:text-2xl md:text-5xl z-0 mx-auto -translate-y-8 sm:-translate-y-12 shadow-xl break-words">
            OUR PARTNERS IN CHANGE
          </div>
        </div>
        <div className="partners">
          {partner.length > 0 &&
            <ScrollerTwo {...partner[0]} len={len} />
          }
        </div>
      </div>
    </div>
  );
};

