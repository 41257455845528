import React from 'react'
import { Link } from 'react-router-dom' 
export const ProjectCard = ({color,title,summary}) => {
    return (
        <div className={`flex flex-col scale-[0.8] sm:scale-[0.85] md:scale-100 h-fit sm:h-[15rem] w-fit sm:w-[49rem] mx-4 md:mx-16 rounded-[20px] p-3 bg-${color}`}>
            <div className="heading m-[10px]">
                {title}
            </div>
            <div className="body line-clamp-4 m-[10px]">
                {summary}
            </div>
            <div className="button flex flex-row justify-end h-[40px] w-full">
                <Link to="/Projects">
                <button className='mx-[10px] p-[8px] fixed bottom-4 right-4 rounded-[10px] bg-[#202020] text-white'>Learn More</button>
                </Link>
            </div>
        </div>
    )
}
