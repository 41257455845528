import React from 'react'

const JoinCard = ({number,name,details}) => {
  return (
    <div className='flex flex-col h-[150px] w-[150px] rounded-full m-[10px] justify-center text-center bg-black '>
      <h1 className="number max-h-fit mb-1 text-white text-4xl ">{number}</h1>
      <h2 className="name text-white uppercase text-lg">{name}</h2>
    </div>
  )
}

export default JoinCard