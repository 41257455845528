import React, { useEffect, useState } from 'react'
import CountUp from 'react-countup'
import ScrollTrigger from 'react-scroll-trigger'

const ImpactCard = ({ number, title, dimension }) => {
  const [counterOn, setCounterOn] = useState(false)
  return (
    <div className={`flex flex-col justify-center items-center ${dimension} rounded-[20px] font-mont text-center bg-black  `}>
      <ScrollTrigger onEnter={() => setCounterOn(true)} >
        <h1 className="number max-h-fit  text-white text-[72px] ">
          {counterOn &&
            <CountUp start={0} end={`${number}`} duration={2} delay={0} />}+
        </h1>
      </ScrollTrigger>
      <h2 className="name  text-white uppercase text-[32px]">{title}</h2>
      {/* <p className="details line line-clamp-3 px-2 text-white text-[16px]">{description}</p> */}
    </div>
  )
}

export default ImpactCard