import React, { useEffect, useState } from "react";
import { MainCarousel } from "../components/MainCarousel";
import leoes from "../assets/leoes.png";
import lions from "../assets/lions.png";
import OurImpact from "../components/OurImpact";
import { ProjectCarousel } from "../components/ProjectCarousel";
import GetinTouch from "../components/GetinTouch"; 
import { SuccessCarousel } from "../components/SuccessCarousel";
import sd1 from "../assets/1.png";
import sd2 from "../assets/2.jpeg";
import sd3 from "../assets/3.jpeg";
import sd4 from "../assets/4.png";
import sd5 from "../assets/5.png";
import sd6 from "../assets/6.jpeg";
import sd7 from "../assets/7.jpeg";
import sd8 from "../assets/8.png";
import sd9 from "../assets/9.jpeg";
import sd10 from "../assets/10.jpeg";
import sd11 from "../assets/11.jpeg";
import sd12 from "../assets/12.jpeg";
import sd13 from "../assets/13.jpeg";
import sd14 from "../assets/14.jpeg";
import sd15 from "../assets/15.png";
import sd16 from "../assets/16.png";
import sd17 from "../assets/17.png";
import globalsd from "../assets/globalsd.png";

import { client } from "../lib/client";
import BlockContent from '@sanity/block-content-to-react';

export const Home = () => {
  const [home, setHome] = useState([]);
  const [projects, setProjects] = useState([]);
  const [success, setSuccess] = useState([]);

  useEffect(() => {
    client.fetch(`*[_type == "home"]{
      impactcard[] -> {id,title,number},
      images[]{
        asset -> {
          _id,
          url
        }
      },
      weserve,
      notification,
    }
    | order(id asc)`).then((data) => {
      setHome(data);
      console.log(data);
    }).catch(console.error);

    client
      .fetch(
        `*[_type == "successstories"]{
            id,
            name,
            description,
            image{
                asset -> {
                    _id,
                    url
                }
            }
        }
        | order(id asc)`
      )
      .then((data) => {
        setSuccess(data);
        console.log(data);
      })
      .catch(console.error);

    client.fetch(`*[_type == "ourprojects"]{
        id,
        title,
        summary,}
        | order(id asc)`).then((data) => {
      setProjects(data)
      console.log(data)
    }).catch(console.error);
  }, []);

  let notification = [];
  if (home.length > 0) {
    for (let i = 0; i < home[0].notification.length; i++) {
      notification.push(
        <li className="mb-8 text-lg md:text-2xl" key={i}>
          {home[0].notification[i]}
        </li>
      )
    }
  }

  const serializers = {
    types: {
      block: props => <p>{props.children}</p>,
    },
    marks: {
      strong: props => <strong>{props.children}</strong>,
    },
  };
  const Weserve = ({ blockContent }) => {
    return <BlockContent blocks={blockContent} serializers={serializers} />;
  };

  return (
    <div className="mt-36">
      <div className="my-4 bg-white">
        {home.length > 0 && (
          <MainCarousel images={home[0].images} />
        )}
      </div>

      <div className="we-serve flex flex-col justify-center bg-yellowland ">
        <div className="heading mt-16 ">

          <div className="md:flex md:justify-center flex items-center justify-center">
            <img className="md:h-[120px] md:w-[120px] md:mr-5 mr-2 h-[70px] w-[70px] drop-shadow" src={leoes} alt="" />
            <h1 className="md:flex md:justify-center md:items-center md:px-10 md:text-7xl md:font-light bg-slate-100 md:rounded-3xl md:py-5 text-4xl px-1 py-5 rounded-xl drop-shadow ">
              "We Serve"
            </h1>
            <img className="md:h-[120px] md:w-[120px] md:ml-5 h-[70px] w-[70px] ml-2 drop-shadow" src={lions} alt="" />
          </div>
        </div>

        


        <div className="md:content md:text-center md:mx-auto md:text-xl md:mb-24 px-6 flex text-center py-10 text-lg mb-10 font-extralight">
          {home.length > 0 && (
            <Weserve blockContent={home[0].weserve} />
          )}
        </div>
      

      <div className="impact bg-white ">
        <div>
          <div className="title bg-black py-5 px-10 w-fit h-fit text-[#f7d858] rounded-[20px] flex justify-center items-center font-bold text-2xl sm:text-3xl md:text-5xl mx-auto z-0 sm:-translate-y-12 -translate-y-8 shadow-xl ">
            OUR IMPACT
          </div>
        </div>
        <div className="flex justify-center items-center flex-col mb-10">
          {home.length > 0 && (
            <OurImpact {...home[0]} />
          )}
        </div>
      </div>

      <div className="projects bg-grayland pb-16">
        <div className="title bg-slate-100 py-[20px] px-[40px] w-fit h-fit text-black rounded-[20px] flex justify-center items-center font-bold text-2xl sm:text-3xl md:text-5xl mx-auto z-0 sm:-translate-y-12 -translate-y-8 shadow-xl">
          OUR PROJECTS
        </div>
        <div className="mx-4 bg-grayland">
          {projects.length > 0 && (
            <ProjectCarousel projects={projects} />
          )}
        </div>
      </div>

      <div className="goals bg-white">
        <div className="bg-white  title py-2 px-2 w-fit h-fit text-black rounded-[20px] flex flex-col justify-center items-center font-bold text-lg  sm:text-2xl md:text-4xl md:px-10 md:py-5 mx-auto z-0 -translate-y-8 sm:-translate-y-12  shadow-xl">
          <h1 className="">A STEP TOWARDS </h1>
          <h2 className="">SUSTAINABLE DEVELOPMENT GOALS</h2>
        </div>
        <div className="justify-center grid lg:grid-cols-6 pt-5 pb-10 md:pl-5 grid-cols-3 px-1 place-items-center">
          <img className="md:h-[220px] md:w-[220px] w-[130px] h-[130px] mb-5 rounded-xl pr-1" src={sd1} alt="" />
          <img className="md:h-[220px] md:w-[220px] w-[130px] h-[130px] mb-5 rounded-xl " src={sd2} alt="" />
          <img className="md:h-[220px] md:w-[220px] w-[130px] h-[130px] mb-5 rounded-xl pl-1" src={sd3} alt="" />
          <img className="md:h-[220px] md:w-[220px] w-[130px] h-[130px] mb-5 rounded-xl pr-1" src={sd4} alt="" />
          <img className="md:h-[220px] md:w-[220px] w-[130px] h-[130px] mb-5 rounded-xl" src={sd5} alt="" />
          <img className="md:h-[220px] md:w-[220px] w-[130px] h-[130px] mb-5 rounded-xl pl-1" src={sd6} alt="" />
          <img className="md:h-[220px] md:w-[220px] w-[130px] h-[130px] mb-5 rounded-xl pr-1" src={sd7} alt="" />
          <img className="md:h-[220px] md:w-[220px] w-[130px] h-[130px] mb-5 rounded-xl" src={sd8} alt="" />
          <img className="md:h-[220px] md:w-[220px] w-[130px] h-[130px] mb-5 rounded-xl pl-1" src={sd9} alt="" />
          <img className="md:h-[220px] md:w-[220px] w-[130px] h-[130px] mb-5 rounded-xl pr-1" src={sd10} alt="" />
          <img className="md:h-[220px] md:w-[220px] w-[130px] h-[130px] mb-5 rounded-xl" src={sd11} alt="" />
          <img className="md:h-[220px] md:w-[220px] w-[130px] h-[130px] mb-5 rounded-xl pl-1" src={sd12} alt="" />
          <img className="md:h-[220px] md:w-[220px] w-[130px] h-[130px] mb-5 rounded-xl pr-1" src={sd13} alt="" />
          <img className="md:h-[220px] md:w-[220px] w-[130px] h-[130px] mb-5 rounded-xl" src={sd14} alt="" />
          <img className="md:h-[220px] md:w-[220px] w-[130px] h-[130px] mb-5 rounded-xl pl-1" src={sd15} alt="" />
          <img className="md:h-[220px] md:w-[220px] w-[130px] h-[130px] mb-5 rounded-xl pr-1" src={sd16} alt="" />
          <img className="md:h-[220px] md:w-[220px] w-[130px] h-[130px] mb-5 rounded-xl" src={sd17} alt="" />
          <img className="md:h-[220px] md:w-[220px] w-[130px] h-[130px] mb-5 rounded-xl pl-1" src={globalsd} alt="" />
                  </div>
      </div>

      <div className="notif+getintouch bg-yellowland flex items-center md:py-20 flex-col lg:flex lg:ml-20 lg:flex-row  " id="getintouch">
        <div className=" md:px-10">
          <GetinTouch/>
        </div>
        <div className="notif flex flex-col md:px-10 md:my-16 lg:my-0">
          <div className="for heading bg-black w-fit rounded-2xl px-5 py-5 ml-10 drop-shadow-2xl md:ml-2">
            <h1 className="text-white text-5xl ">Notifications</h1>
          </div>
          <div className="for list py-8 ml-8 mr-4 font-light">
            <ul className="list-disc ">
              {notification}
            </ul>
          </div>
        </div>
      </div>

      <div className="success bg-white">
        <div className="title bg-grayland py-[20px] px-[40px] w-fit h-fit text-black rounded-[20px] flex justify-center items-center font-bold text-2xl sm:text-3xl md:text-5xl mx-auto z-0 sm:-translate-y-12 -translate-y-8 shadow-xl">
          SUCCESS STORIES
        </div>
        <div className="flex justify-center">
          {success.length > 0 && (
            <SuccessCarousel success={success} />
          )}
        </div>
      </div>
    </div>
    </div>
  );
};
