import React from 'react'

export const ProjectsIntro = () => {
    return (
        <div className='wrapper flex flex-col gap-3 h-[100vh] justify-center p-14'>
            <span className='font-normal text-lg sm:text-3xl'>
                Small steps to make a
            </span>
            <span className='text-6xl sm:text-9xl font-bold'>
                <span>
                    Big Imp
                </span>
                <span className='text-[#f7d858]'>
                    act
                </span>
            </span>
            <span className='font-semibold text-base mt-5 sm:text-lg w-[80vw] sm:w-[40vw]'>
                "The best way to find happiness is not by searching for it, but by giving it to others." <br />

            </span>
        </div>
    )
}
