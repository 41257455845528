import React from 'react'
import ImpactCard from './ImpactCard'
import { ProjectImg } from './ProjectImg'

const ProjectSlide = ({ id, title, tagline, images, drivesdescription, missiondescription, founder, chairperson, impactcard }) => {
    let drives = [];
    for (let i = 0; i < drivesdescription.length; i++) {
        drives.push(<li className="text-base sm:text-lg m-1 break-words">{drivesdescription[i]}</li>)
    }
    return (
        <div className="wrapper h-screen w-full flex flex-col overflow-y-scroll mr-2">
            <div className="heading flex flex-row h-fit mx-1 mt-4 md:mt-8 md:mb-6  p-2">
                <div className="title max-w-fit my-auto ml-2 md:ml-10 break-words flex flex-row" >
                    <h1 className='text-3xl sm:text-6xl font-extrabold'>{title}</h1>
                    <div className="summary text-lg sm:text-2xl max-w-fit text-[#3b6284] items-end ml-4 flex justify-center break-words font-semibold ">
                        <p>{tagline}</p>
                    </div>
                </div>
            </div>
            
            <div className="middle flex flex-col sm:flex-row justify-around h-fit md:mb-10 mx-2">
                <div className="mission w-fit mt-4 md:mt-16 sm:my-auto mx-2 p-2">
                    <h1 className='text-lg sm:text-xl text-[#3B6284]'>MISSION</h1>
                    <span className='text-base sm:text-xl break-words'>{missiondescription}</span>
                </div>
                <div className="impact w-fit h-fit mx-auto sm:my-auto flex flex-col justify-center items-center p-1 scale-75 sm:scale-100 lg:mt-20">
                    {/* <h1 className='text-lg sm:text-xl text-[#3B6284]'>IMPACT</h1> */}
                    <ImpactCard {...impactcard} dimension='h-auto w-fit p-10'/>
                </div>
            </div>

            <div className="end flex flex-col sm:flex-row h-fit sm:items-center ">
                <div className="drives w-full h-fit sm:h-full flex justify-center sm:mx-8 ">
                    <ProjectImg images={images} />
                </div>
                <div className="bullets w-full flex items-center ">
                    <ul className='list-disc m-4 break-words '>
                        {drives}
                    </ul>
                </div>
                <div className="by-line w-full flex flex-row sm:flex-col text-lg mx-1">
                    <div className="founder m-2 flex flex-col max-w-[55vw]">
                        <h1 className='text-[#3B6284]'>Founder:</h1>
                        <span>{founder}</span>
                    </div>
                    <div className="chairperson m-2 flex flex-col">
                        <h1 className='text-[#3B6284] '>Chairperson:</h1>
                        <span className='break-inside-auto'>{chairperson}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectSlide;
