import React, { useState } from "react";
import linkdin from "../assets/linkdin.svg";
import plus from "../assets/plus.svg";
import minus from "../assets/minus.svg";

import BlockContent from '@sanity/block-content-to-react';

import '../styles/Profile.css'

export const Profile = ({ title, image, designation, name, linkedin,bio }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  console.log({ isFlipped });

  const serializers = {
    types: {
      block: props => <p>{props.children}</p>,
    },
    marks: {
      strong: props => <strong>{props.children}</strong>,
    },
  };
  const Bio = ({ blockContent }) => {
    return <BlockContent blocks={blockContent} serializers={serializers} />;
  };

  return (
    <div
      className={(isFlipped ? "flipped" : "") + " flip-card-toggle"}
      onClick={() => setIsFlipped(!isFlipped)}
    >
      <div className="flip-card-toggle-inner">
        {/* front */}
        <div className="flip-card-toggle-front">
          <div className="grid place-items-center">
            <div className="group relative border-gray-100/30 flex w-full max-w-xs flex-col self-center overflow-hidden rounded-lg border bg-black shadow-md min-w-fit">
              <div className="z-10 cursor-pointer opacity-10 absolute right-0 hover:opacity-100 ">
                <img
                  className="h-[54px] w-[54px] border-black "
                  src={plus}
                  alt="plus"
                />
              </div>

              <div className="relative mx-3 mt-3 flex h-60 overflow-hidden rounded-sm">
                <img
                  className="peer absolute z-0 top-0 right-0 h-auto w-full object-cover"
                  src={image.asset.url}
                  alt={name}
                />
              </div>
              <div className="mt-4 px-5 pb-3 flex flex-col">
                <span className="text-xl font-medium text-white mb-2 flex flex-row">
                  {name}
                </span>

                <span className="text-xl flex-row flex font-thin tracking-wide text-white">
                  {designation}
                  <div className="imagewrapper cursor-pointer ml-auto">
                    <a href={linkedin}>
                      <img className="h-[32px] w-[32px]" src={linkdin} alt="" />
                    </a>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
          {/* back */}
        <div className="flip-card-toggle-back">
          <div className="grid place-items-center">
            <div className="group relative border-gray-100/30 flex w-full max-w-xs flex-col self-center overflow-hidden rounded-lg border bg-black shadow-md min-w-fit">
              <div className="z-10 cursor-pointer opacity-10 absolute right-0 hover:opacity-100 ">
                <img
                  className="h-[54px] w-[54px] border-black "
                  src={minus}
                  alt="minus"
                />
              </div>

              <div className="relative p-3 flex w-full max-w-[320px] flex-col self-center overflow-hidden rounded-lg  bg-black shadow-md min-w-fit min-h-[350px] max-h-fit">
                <span className="text-white text-center my-auto text-lg">
                  {bio}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
