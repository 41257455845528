import React from 'react'
import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";
import chevronDown from "../assets/chevron-down.svg";
import styles from "../styles/faq.module.css";

export const Faq = ({values}) => {
    const AccordionItem = ({ header, ...rest }) => (
        <Item
            {...rest}
            header={
                <>
                    {header}
                    <img className={styles.chevron} src={chevronDown} alt="Chevron Down" />
                </>
            }
            className={styles.item}
            buttonProps={{
                className: ({ isEnter }) =>
                    `${styles.itemBtn} ${isEnter && styles.itemBtnExpanded}`
            }}
            contentProps={{ className: styles.itemContent }}
            panelProps={{ className: styles.itemPanel }}
        />
    );
    let comp=[];
    for(let i=0;i<values.length;i++){
        comp.push(<AccordionItem header={values[i].question} key={i}>{values[i].answer}</AccordionItem>)
    }
    return (
        <div className='wrapper'>
            <div className={styles.app} >
                {/* `transitionTimeout` prop should be equal to the transition duration in CSS */}
                <Accordion transition transitionTimeout={250}>
                    {comp}
                </Accordion>
            </div>
        </div>
    )
}
