import React, { useEffect, useState } from "react";

import { client } from "../lib/client";
import BlockContent from '@sanity/block-content-to-react';

import { Profile } from "../components/Profile";
import Techies from "../components/Techies";

const Team = () => {
  const [profile, setProfile] = useState([]);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "profile"]{
          id,
        title,
        name,
        designation,
        image{
          asset -> {
        _id,
        url,
      },
    },
    linkedin,
    bio}
    | order(id asc)`
      )
      .then((data) => {
        setProfile(data);
        console.log(data);
      })
      .catch(console.error);
  }, []);

  const serializers = {
    types: {
      block: props => <p>{props.children}</p>,
    },
    marks: {
      strong: props => <strong>{props.children}</strong>,
    },
  };
  const Bio = ({ blockContent }) => {
    return <BlockContent blocks={blockContent} serializers={serializers} />;
  };

  return (
    <div className="container w-[100vw]">
      <section className=" body flex flex-col bg-[#f7d858] w-[100vw]">
        <div className="heading flex items-center h-fit w-fit text-3xl md:text-5xl lg:text-6xl font-bold text-center bg-white my-4 px-10 py-5  rounded-r-[25px]">
          CHANGEMAKERS
        </div>

        {/* title field is case sensitive, write the title in LOWERCASE ONLY */}
        <div className="core bg-[#f7d858] my-4">
          <span className="block mb-7 text-center font-bold text-white text-7xl md:text-8xl lg:text-9xl font-outline-3 md:font-outline-4 lg:font-outline-5 tracking-wider">
            CORE
          </span>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 w-full mb-10">
            {profile.map(
              (p,index) =>
                p.title === "core" && (
                  <div className="">
                    <Profile key={p.image.asset.url} {...p} bio={<Bio blockContent={profile[index].bio}/>}/>
                  </div>
                )
            )}
          </div>
        </div>

        <div className="cabinet bg-white">
          <span className="block my-5 text-center font-bold text-[#f7d858] text-7xl md:text-8xl lg:text-9xl font-outline-3 md:font-outline-4 lg:font-outline-5 tracking-wider">
            CABINET
          </span>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 w-full mb-10">
            {profile.map(
              (p,index) =>
                p.title === "cabinet" && (
                  <div className="">
                    <Profile key={p.image.asset.url} {...p} bio={<Bio blockContent={profile[index].bio}/>}/>
                  </div>
                )
            )}
          </div>
        </div>

        <div className="core bg-[#f7d858] my-4">
          <div className="heading flex items-center h-fit w-fit text-3xl md:text-5xl lg:text-6xl font-bold text-center bg-white my-4 md:px-8 lg:px-10 py-5  rounded-r-[25px]">
            DESIGNED AND DEVELOPED BY
          </div>
          <Techies />
        </div>
      </section>
    </div>
  );
};

export default Team;
