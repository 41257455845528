import React from "react";
import TechyCard from "./TechyCard";
import anchit from "../assets/anchit.webp";
import lakshay from "../assets/lakshay.webp";
import parth from "../assets/parth.webp";
import aditya from "../assets/aditya.webp";

const Techies = () => {
  return (
    <div>
      <section class="">
        <div class="container px-6 md:py-10 mx-auto">
          <div class="grid grid-cols-1 gap-6 mt-8 xl:mt-16 md:grid-cols-2 xl:grid-cols-4">
            <TechyCard name={"Aditya Vohra"} photo={aditya} linkedin={"https://www.linkedin.com/in/adityavohra2003"} github={"https://github.com/adityavohra2003"} twitter={"https://twitter.com/AdityaVohra15"}/>
            <TechyCard name={"Anchit Bansal"} photo={anchit} linkedin={"https://www.linkedin.com/in/anchit-bansal-39322317a"} github={"https://github.com/anchit11"} twitter={"https://twitter.com/anchitbansal11"}/>
            <TechyCard name={"Lakshay Arora"} photo={lakshay} linkedin={"https://www.linkedin.com/in/lakshayarora162/"} github={"https://github.com/Lakshay-a"} twitter={"https://twitter.com/Lakshay_arora14"}/>
            <TechyCard name={"Parth Juneja"} photo={parth} linkedin={"https://www.linkedin.com/in/parth-juneja-861370211/"} github={"https://github.com/ParthJuneja"} twitter={"https://twitter.com/pjparties"}/>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Techies;
