import React from "react";
import {RxLinkedinLogo} from "react-icons/rx"
import {RiTwitterFill} from "react-icons/ri"
import {FaGithub} from "react-icons/fa"

const TechyCard = ({name,photo,twitter,linkedin,github}) => {
  return (
    <>
      <div class="card flex flex-col items-center p-8 transition-colors duration-300 transform border cursor-pointer rounded-xl hover:border-transparent group hover:bg-lightblack bg-white">
        <img
          class="object-cover w-32 h-32 rounded-full ring-4 ring-gray-300"
          src={photo}
          alt=""
        />

        <h1 class="mt-4 text-2xl font-semibold text-gray-700 capitalize group-hover:text-white">
          {name}
        </h1>

        <div class="flex mt-3 -mx-2">
          <a
            href={twitter}
            class="mx-2 text-gray-600 hover:text-gray-500 group-hover:text-white"
            aria-label="Reddit"
            target="_blank"
            rel="noreferrer"
          >
            <RiTwitterFill class="w-6 h-6 fill-current" />
          </a>

          <a
            href={linkedin}
            class="mx-2 text-gray-600 hover:text-gray-500 dark:hover:text-gray-300 group-hover:text-white"
            aria-label="Facebook"
            target="_blank"
            rel="noreferrer"
          >
            <RxLinkedinLogo class="w-6 h-6 fill-current" />
          </a>

          <a
            href={github}
            class="mx-2 text-gray-600  hover:text-gray-500 dark:hover:text-gray-300 group-hover:text-white"
            aria-label="Github"
            target="_blank"
            rel="noreferrer"
          >
            <FaGithub class="w-6 h-6 fill-current" />
          </a>
        </div>
      </div>
    </>
  );
};

export default TechyCard;
