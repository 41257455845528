import React, { useEffect, useState } from "react";
import { MainCarousel } from "../components/MainCarousel";
import { AwardsCard } from "../components/AwardsCard";
import { SuccessCard } from "../components/SuccessCard";
import Alumni from '../components/Alumni'

import { client } from "../lib/client";

export const Achievements = () => {
    const [carouselImages, setCarouselImages] = useState([]);
    const [successStories, setSuccessStories] = useState([]);
    const [alumni, setAlumni] = useState([]);
    const [award, setAward] = useState([]);
    useEffect(() => {
        client
            .fetch(
                `*[_type == "achievements"]{
            images[]{
              asset -> {
                _id,
                url
              }
            }
          }`
            )
            .then((data) => {
                setCarouselImages(data);
                console.log(data);
            })
            .catch(console.error);

        client
            .fetch(
                `*[_type == "successstories"]{
            id,
            name,
            description,
            image{
                asset -> {
                    _id,
                    url
                }
            }
        }
        | order(id asc)`
            )
            .then((data) => {
                setSuccessStories(data);
                console.log(data);
            })
            .catch(console.error);

        client
            .fetch(
                `*[_type == "alumni"]{
            id,
            year,
            profile []->{
                title,
                name,
                year,
                designation,
                 image{
                    asset -> {
                        _id,
                        url
                    },
                },
                linkedin}
            }`
            )
            .then((data) => {
                setAlumni(data);
                console.log(data);
            })
            .catch(console.error);

        client
            .fetch(
                `*[_type == "awards"]{
            id,
            description,
        }
        | order(id asc)`
            )
            .then((data) => {
                setAward(data);
                console.log(data);
            })
            .catch(console.error);


    }, []);

    const getSidebarTabColor = (tabIndex) => {
        return tabIndex % 2 === 0 ? "yellowland" : "git2";
    };
    return (
        <div className="container bg w-[100vw] ">
            <section className="body flex flex-col w-[100vw] bg-white">
                <div className="flex justify-center items-center my-10">
                    <div className="title bg-[#202020] text-white rounded-[20px] py-5 px-10 h-fit w-fit flex justify-center items-center font-bold text-xl sm:text-2xl md:text-5xl text-center">
                        CLUB ACHIEVEMENTS
                    </div>
                </div>

                <div className="carousel mb-16">
                    {carouselImages.length > 0 && (
                        <MainCarousel images={carouselImages[0].images} />
                    )}
                </div>

                <div className="awards bg-[#f7d858] mt-8 ">
                    <div>
                        <div className="title text-white py-5 px-10 h-fit w-fit bg-[#f7d858] rounded-[20px] flex justify-center items-center font-bold text-xl sm:text-2xl md:text-5xl z-0 mx-auto -translate-y-8 sm:-translate-y-12 shadow-xl break-words">
                            AWARDS & ACCREDITATIONS
                        </div>
                    </div>
                    <div className="card grid grid-cols-1 lg:grid-cols-2 gap-2 place-items-center mb-16 sm:mb-32">
                        {award.map((award, index) => (
                            <AwardsCard key={award.id} {...award} />
                        ))}
                    </div>
                </div>

                <div className="success bg-white ">
                    <div>
                        <div className="title py-5 px-10 h-fit w-fit bg-white rounded-[20px] flex justify-center items-center font-bold text-xl sm:text-2xl md:text-5xl mx-auto z-0 -translate-y-8 sm:-translate-y-12 shadow-xl">
                            SUCCESS STORIES
                        </div>
                    </div>
                    <div className="card grid grid-cols-1 lg:grid-cols-2 place-items-center gap-2 mb-16 sm:mb-32">
                        {successStories.map((story, index) => (
                            <SuccessCard
                                key={story.id}
                                {...story}
                                color={getSidebarTabColor(index)}
                                line="line-clamp-9"
                                scale="scale-[0.53] xs:scale-[0.59]"
                            />
                        ))}
                    </div>
                </div>
                <div className="alumni bg-[#f7d858] mt-4">
                    <div>
                        <div className="title text-white py-5 px-10 h-fit w-fit bg-[#f7d858] rounded-[20px] flex justify-center items-center font-bold text-xl sm:text-2xl md:text-5xl mx-auto z-0 -translate-y-8 sm:-translate-y-12 shadow-xl">
                            ALUMNI SECTION
                        </div>
                    </div>
                    <div className="card pointer-events-none  mb-32">
                        {alumni.map((item, index) => (
                            <Alumni key={item.id} {...item} />
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};
