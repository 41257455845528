import React from "react";
import { RxLinkedinLogo } from "react-icons/rx";
import { FaInstagramSquare } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { IoIosCall } from "react-icons/io";
import leoes from "../assets/leoes.png";

const Footer = () => {
  return (
    <footer class="bg-lightblack">
      <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div class="sm:flex sm:items-center sm:justify-between">
          <a href="/" class="flex items-center mb-4 sm:mb-0">
            <img src={leoes} class="h-12 sm:h-16 lg:h-20 mr-3" alt="Logo" />
            <span class="self-center text-2xl lg:text-3xl font-medium cursor-pointer whitespace-nowrap text-fyellow">
              Leo Club Eternity Service
            </span>
          </a>
          <ul class="flex flex-wrap items-center mb-6 text-sm md:text-base lg:text-lg font-medium sm:mb-0 justify-center sm:justify-normal text-white">
            <li className="text-center sm:text-left">
              <h4 href="#" class="mr-4 md:mr-6 text-fyellow">
                Reach Us
              </h4>
              <li className="EMAIL hover:underline flex gap-1 items-center py-1">
                <FiMail/>
                <a href="mailto:leoclubeternityservice.india@gmail.com">
                leoclubeternityservice.india@gmail.com
                </a>
              </li>
              <li className="PHONE hover:underline flex gap-1 justify-center sm:justify-normal items-center">
                <IoIosCall/>
                <a href="tel:+91 88269 22893">+91 88269 22893</a>
                <span>(Anchit Bansal)</span>
              </li>
            </li>
          </ul>
        </div>
        <hr class="my-2 sm:mx-auto lg:my-4" />
        <div className="footer__social">
          <h3 className="text-fyellow font-thin text-center text-sm  md:text-base lg:text-lg mb-1">
            Follow Us
          </h3>
          <ul className="text-white flex-row justify-center flex gap-8 md:gap-16 lg:gap-32">
            <a href="https://www.facebook.com/profile.php?id=100090833146002">
              <li>
                <FaFacebookSquare className="w-8 h-8 text-white hover:text-gray-500" />
              </li>
            </a>
            <a href="https://www.linkedin.com/company/leo-club-eternity-service-weserve/mycompany/">
              <li>
                <RxLinkedinLogo className="w-8 h-8 text-white hover:text-gray-500" />
              </li>
            </a>
            <a href="https://www.instagram.com/leoclubeternityservice/">
              <li>
                <FaInstagramSquare className="w-8 h-8 text-white hover:text-gray-500" />
              </li>
            </a>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
