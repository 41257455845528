import React from 'react'

export const SuccessCard = ({ name, description, image, color,line,scale }) => {
  return (
    <div className={`flex flex-row w-[40rem] md:w-[38rem] h-fit bg-${color} rounded-[20px] p-4 -my-10 sm:my-4 ${scale}  sm:scale-[0.8] md:scale-100`}>
      <div className='pic m-auto w-[45vw] sm:w-[35vw]'>
        <img src={image.asset.url} alt={name} className='object-cover w-full h-auto rounded-full' />
      </div>
      <div className='body flex flex-col items-center justify-center w-full md:w-[65vw] ml-2 md:ml-4 text-center'>
        <div className='title h-fit text-4xl my-4'>
          <h1>{name}</h1>
        </div>
        <div className={`body h-full ${line} text-lg sm:text-sm`}>
          <span>
            {description}
          </span>
        </div>
      </div>
    </div>
  )
}
