import React from "react";
import Footer from "../components/Footer";
import  {Nav}  from "../components/Nav";


export const NavFooterWrapper = ({ children }) => {
  return (
    <div className="">
      <div className="sticky top-0 z-50 w-[100vw] mb-28">
        <Nav />
      </div>
      <div className="w-[100vw] mt-0">
        {children}
      </div>
      <div className="">
        <Footer />
      </div>
    </div>
  );
}

export default NavFooterWrapper;