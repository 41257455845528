import React, { useEffect, useState } from 'react';
import '../styles/Projects.css';
import ProjectSlide from '../components/ProjectSlide';
import { Nav } from '../components/Nav';
import { client } from "../lib/client";
import { ProjectsIntro } from '../components/ProjectsIntro';
import { Others } from '../components/Others';
import Footer from '../components/Footer';
import Error from './ErrorPage';
import { Link } from 'react-router-dom';

export const Projects = () => {
    const [activeTab, setActiveTab] = useState(1);
    const [linePosition, setLinePosition] = useState(0);
    const [projects, setProjects] = useState([]);
    const [x, setX] = useState(0);

    useEffect(() => {
        client.fetch(`*[_type == "ourprojects"]{
            title,
            id,
            tagline,
            founder,
            chairperson,
            drivesdescription[],
            missiondescription,
            impactcard->{title,description,number},
            images[]{
                asset -> {
                    _id,
                    url
                },
            }}
            | order(id asc)`).then((data) => {
            setProjects(data)
            console.log(data)
        }).catch(
            console.error,
            // <Link to="/*"></Link>
            );
        setX(projects.length - 1)

        const handleTabClick = (event) => {
            const { ddActiveTab, ddSidebarTab } = event.target.dataset;
            setActiveTab(Number(ddActiveTab));
            document.querySelector('.sidebar-content').style.background = event.target.getAttribute('data-dd-sidebar-tab');


            const scrollElement = document.getElementById(`content_${ddActiveTab}`);
            scrollElement.scrollIntoView({ behavior: 'smooth' });
        };

        const handleScroll = () => {
            const navbarHeight = document.querySelector('.navbar').offsetHeight;
            const footerHeight = document.querySelector('.footer').offsetHeight;
            setX(navbarHeight);
            const scrollPos = window.scrollY + navbarHeight + footerHeight;

            const links = document.querySelectorAll('.sidebar-list-menu li a');

            links.forEach((link) => {
                const { ddActiveTab, ddSidebarTab } = link.dataset;
                const refElement = document.querySelector(link.getAttribute('href'));
                const refElementOffsetTop = refElement.offsetTop;
                const refElementOffsetBottom = refElementOffsetTop + refElement.offsetHeight;

                if (refElementOffsetTop - navbarHeight <= scrollPos && refElementOffsetBottom > scrollPos) {
                    setActiveTab(Number(ddActiveTab));
                    document.querySelector('.sidebar-content').style.background = ddSidebarTab;
                    setLinePosition(link.parentElement.offsetTop + link.parentElement.offsetHeight * 0.5 - 1 - (link.parentElement.offsetHeight / 2));


                }
            });
        };

        document.querySelectorAll('[dd-sidebar-tab]').forEach((element) => {
            element.addEventListener('click', handleTabClick);
        });

        window.addEventListener('scroll', handleScroll);

        return () => {
            document.querySelectorAll('[dd-sidebar-tab]').forEach((element) => {
                element.removeEventListener('click', handleTabClick);
            });

            window.removeEventListener('scroll', handleScroll);
        };
    }, [projects.length]);

    const getSidebarTabColor = (tabIndex) => {
        return tabIndex % 2 === 0 ? '#f7d858' : '#fff696';
    };


    let side = [];
    side.push(<li key={0}>
        <a href="#content_0"
            className={`activeTab === 0 ? 'active' : ''`}
            data-dd-active-tab="0"
            data-dd-sidebar-tab={getSidebarTabColor(0)}>
            Our Projects
        </a>
    </li>)
    for (let i = 0; i < projects.length; i++) {
        side.push(<li key={i + 1}>
            <a href={`#content_${i + 1}`}
                className={`activeTab === ${i + 1} ? 'active' : ''`}
                data-dd-active-tab={`${i + 1}`}
                data-dd-sidebar-tab={getSidebarTabColor(i + 1)}>
                {projects[i].title}
            </a>
        </li>)
    }

    let content = [];
    content.push(<div class="content-container">
        <div id={`content_0`} class="content--center">
            <ProjectsIntro key={0} />
        </div>
    </div>)
    for (let i = 0; i < projects.length; i++) {
        if (i === projects.length - 1) {
            content.push(<div class="content-container">
                <div id={`content_${i + 1}`} class="content--center">
                    < Others key={`${i + 1}`} {...projects[i]} />
                </div>
            </div>)
        }
        else {
            content.push(<div class="content-container">
                <div id={`content_${i + 1}`} class="content--center">
                    <ProjectSlide key={i + 1} {...projects[i]} />
                </div>
            </div>)
        }


    }
    return (
        <div>

            <div className="dd-wrapper ">
                <div className='navbar z-10 sticky top-0 w-full'>
                    <Nav />
                </div>
                <div className="sidebar" style={{ marginTop: `${x}` }}>
                    <div className="sidebar-content ">
                        <div className="sidebar-list">
                            <ul className="sidebar-list-menu">
                                <span className="sidebar-list-menu-active-bar"
                                    style={{ top: `${linePosition}px` }}></span>
                                {side}
                                {/* Rest of the tabs */}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="content">
                    {content}
                </div>

            </div>
            <div className="footer sticky ">
                <Footer />
            </div>
        </div>
    );
};
