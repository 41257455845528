import React, { useEffect, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

export const ProjectImg = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleOnSlideChange = (event) => {
        setCurrentIndex(event.item);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((currentIndex + 1) % images.length);
        }, 3000);
        return () => clearInterval(interval);
    }, [currentIndex, images]);

    return (
        <div className=" w-full sm:w-[40vw] lg:w-[25vw] mx-2">
            <AliceCarousel
                items={images.map((image, index) => (
                    <div key={index} className="dropShadow-2xl">
                        <img
                            className="w-[60vw] sm:w-[50vw] lg:w-[25vw] h-[35vh] object-cover mx-auto rounded-2xl mt-1 sm:mt-4"
                            src={image.asset.url}
                            alt={image.asset._id}
                        />
                    </div>
                ))}
                activeIndex={currentIndex}
                onSlideChanged={handleOnSlideChange}
                autoPlayInterval={3000}
                autoPlayDirection="ltr"
                autoPlay={true}
                fadeOutAnimation={true}
                mouseDragEnabled={true}
                dotsDisabled={true}
                buttonsDisabled={true}
                showSlideInfo={false}
                disableButtonsControls={true}
                responsive={{
                    0: { items: 1 },
                    768: { items: 1 },
                    1024: { items: 1 },
                }}
            />
        </div>
    );
};
