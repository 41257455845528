import React, { useRef } from "react";
import joinus from "../assets/joinus.png";
import { Nav } from "../components/Nav";
import join from "../assets/join.png";
import JoinCard from "../components/JoinCard";
import Footer from "../components/Footer";

import "../styles/JoinUs.css";

export const JoinUs = () => {
    const myRef = useRef(null)

    const executeScroll = () => myRef.current.scrollIntoView()
  return (
    <div className="topcontainer">
      <div className="imagecontainer pt-2 sm:pt-0 min-h-screen: w-full relative flex items-center justify-center ">
        <img src={joinus} alt="Join Us" className="w-full h-full" />
        <div className="textwrapper absolute flex flex-col items-center justify-center mb-2">
          <div className="content div flex flex-col items-center justify-between w-fit pb-2 md:pb-4">
            <h1
              className="text-yellowland md:text-5xl lg:text-6xl text-xl drop-shadow-2xl border-black"
              id="becomea"
            >
              Become a
            </h1>
            <h2
              className="text-white text-world text-6xl md:text-8xl lg:text-9xl drop-shadow-2xl border-black"
              id="leo"
            >
              LEO
            </h2>
          </div>

          <div className="joinus-button">
            <button class="drop-shadow-2xl bg-transparent md:text-xl whitespace-nowrap bg-white hover:bg-gray-300 text-black font-light px-4 border border-black rounded-lg h-7 w-20 md:h-12 md:w-32"
              onClick={executeScroll}>
              Join Us
            </button>
          </div>
        </div>
      </div>

      <div className="article-section flex flex-col sm:flex-row py-4 sm:py-8 text-start flex-wrap px-10">
        <div className="flex flex-col sm:max-w-[70vw] md:max-w-[90vw] lg:max-w-[60vw]">
          <h1
            className="text-3xl sm:text-5xl mb-5 text-yellowland font-bold"
          >
            Why Join Us?
          </h1>
          <p className="text-lg mb-4 font-extralight">
            Impact: Join the world's largest community service organization,
            enabling you to make a significant global impact through
            participation in projects and initiatives. <br />
            Exposure: Gain international recognition and exposure through
            participation in conferences and exchange programs.
            <br />
            Academic Portfolio: Build and Enhance your CV, showcasing your
            dedication to community service and leadership skills. <br />
            Receive certificates and potential letter of recommendations for
            exceptional performance.
            <br />
            Collaboration and Networking: Exceptional Networking Opportunities
            fostering personal growth through communication.
            <br /> Growth: Develop and refine leadership skills and abilities of
            teamwork, collaboration, management etc through various
            opportunities within the organization.
            <br /> Recognition: Earn prestigious Leo Pins and other accolades
            for your outstanding contributions to the community.
          </p>
        </div>
        <div className="image-wrapper m-auto">
          <img className="sm:h-80 rounded-lg" src={join} alt="" />
        </div>
      </div>

      <div className="flex flex-col lg:flex-row items-center lg:items-start ">
        <div className="infocard w-fit grid grid-cols-2 grid-rows-2 max-h-96 pb-6 min-w-fit lg:pr-5 m-auto">
          <JoinCard number={"10+"} name={"Projects"} />
          <JoinCard number={"20+"} name={"Volunteers"} />
          <JoinCard number={"10+"} name={"Funded"} />
          <JoinCard number={"100+"} name={"Projects"} />
        </div>
        <div className="article-section flex flex-col sm:flex-row text-start flex-wrap px-10">
          <div className="flex flex-col sm:max-w-[70vw] md:max-w-[90vw] lg:max-w-[60vw]">
            <h1 className="text-3xl sm:text-5xl mb-5 text-yellowland font-bold" >
              How can your contribution make a difference?
            </h1>
            <p className="text-lg mb-4 font-extralight">
              The contribution of an individual can bring transformative changes
              for many, as it has been greatly said "There could be no
              definition of a successful life that does not include service to
              others."
              <br />
              Your obliging act of contribution can be in three forms. <br />
              <li>
                Time and Effort- being a volunteer requires contribution
                of effort, time and skill. Through your dedication, compassion,
                and collective efforts, you can address social, environmental
                and humanitarian issues.{" "}
              </li>
              <li>Kind such as stationery, clothes, food, etc. </li>
              <li>
                Monetary Donations are also accepted The verity is, your
                offering counts, no matter how grand or slight. We look forward
                to the support and love of all the humans out there.
              </li>
            </p>
          </div>

        </div>
      </div>

      <div ref={myRef} className="py-20 flex justify-center bg-yellowland">
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSdPbofwuKiYeVwCaTbYaJS9fkkz4ud79BLkI861RI6j-OpFWg/viewform?embedded=true"
          className="w-[100vw] h-[100vh]"
        >
          Loading…
        </iframe>
      </div>
    </div>
  );
};
