import React from 'react'
import {OtherImg} from './OtherImg'

export const Others = ({title,tagline,images,drivesdescription}) => {
    let drives=[];
    for(let i=0;i<drivesdescription.length;i++){
        drives.push(<li className="text-base sm:text-lg m-1 break-words">{drivesdescription[i]}</li>)
    }
    return (
        <div className='wrapper h-screen w-screen flex flex-col overflow-y-scroll overflow-x-none'>
            <div className="heading flex flex-row h-fit mx-2 mt-4 sm:mt-8 sm:mb-10  ">
                <div className="title max-w-fit my-auto ml-2 md:ml-10 break-words flex flex-row" >
                    <h1 className='text-3xl sm:text-6xl font-extrabold'>{title}</h1>
                    <div className="summary text-lg sm:text-2xl max-w-fit text-[#3b6284] items-end ml-2 md:ml-4 flex justify-center break-words font-semibold ">
                        <p>{tagline}</p>
                    </div>
                </div>
            </div>

            <div className="end flex flex-col lg:flex-row h-fit md:ml-4">
                <div className="drives w-fit h-fit sm:h-full flex justify-center mb-6 lg:mb-0">
                    <OtherImg images={images}/>
                </div>
                <div className="bullets w-fit max-w-[300px] flex items-center mx-4 lg:mx-0">
                    <ul className='list-disc p-2 break-words'>
                        {drives}
                    </ul>
                </div>
            </div>
        </div>
    )
}
