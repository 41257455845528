import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

import { SuccessCard } from './SuccessCard'
import "../styles/SuccessCarousel.css"

export const SuccessCarousel = ({ success }) => {
  const getSidebarTabColor = (tabIndex) => {
    return tabIndex % 2 === 0 ?"yellowland" : "git2";
  };
  return (
    <div className="container">
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Autoplay, Navigation]}
        className="mySwiper"
      >
        {success.map((item, index) => (
          <SwiperSlide className='swip'>
            <SuccessCard title={item.title}
              description={item.description}
              image={item.image}
              name={item.name}
              color={getSidebarTabColor(index)} 
              line="line-clamp-6"
              scale='scale-[0.75] xs:scale-[0.8]'/>
              
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}


