import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "../styles/ScrollerTwo.css";

const ScrollerTwo= ({images})=> {
    const settings = {
      dots: false,
      autoplay: true,
      autoplaySpeed: 1500,
      arrows: false,
      pauseOnHover: true,
      infinite: true,
      centerMode: true,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 1,
      initialSlide: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
      ],
    };
    let img = [];
    if (images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        img.push(
          <div className="card my-4">
            <img
              src={images[i].asset.url}
              alt="company"
              className="px-2 h-auto w-[100%] object-cover"
            />
          </div>
        );
      }
    }
    return (
      <>
        <Slider {...settings}>
          {img}
        </Slider>
      </>
    );
}

export default ScrollerTwo;
