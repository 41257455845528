import React from "react";

function Scroller({images,len}) {
  let img = [];
  
  if (images.length > 0) {
    for (let i = 0; i < images.length; i++) {
      img.push(
        <li className="m-2">
          <img src={images[i].asset.url} alt="" className="w-[250px] h-[250px] object-cover" />
        </li>
      );
    }
  }

  return (
    <div className="w-full">
      <div className="h-[200px] m-auto overflow-hidden relative w-auto">
        <ul className={`flex w-[calc(250px*${len})] animate-scroll`}>
          {img}
        
        </ul>
      </div>
      <div className="h-[200px] m-auto overflow-hidden relative w-auto">
        <ul className={`flex w-[calc(250px*${len})] animate-back`}>
          {img}
        </ul>
      </div>
    </div>
  );
}

export default Scroller;
