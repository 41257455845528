import React from 'react'
import award from '../assets/award.png'
import BlockContent from '@sanity/block-content-to-react';


export const AwardsCard = ({description}) => {
    const serializers = {
        types: {
          block: props => <p>{props.children}</p>,
        },
        marks: {
          strong: props => <strong>{props.children}</strong>,
        },
      };

      const Description = ({ blockContent }) => {
        return <BlockContent blocks={blockContent} serializers={serializers} />;
      };
      
      
    return (
        <div className='grid place-items-center -my-8 sm:my-4 scale-[0.53] xs:scale-[0.6] sm:scale-[0.8] md:scale-100 '>
            <div className='w-fit min-h-fit h-[20rem] sm:h-[13rem] bg-white rounded-[20px] drop-shadow-lg flex flex-row justify-center items-center p-4'>
                <div className="img w-44 mr-2">
                    <img src={award} alt="award" className='w-[175px] h-[158px] ' />
                </div>
                <div className="body w-96 text-center flex items-center  text-lg sm:text-sm">
                    <Description blockContent={description} />
                </div>
            </div>
        </div>
    )
}
