import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import NavFooterWrapper from './hoc/NavFooterWrapper';
import {AboutUs} from './pages/AboutUs';
import { Achievements } from './pages/Achievements';
import App from './pages/App';
import ErrorPage from './pages/ErrorPage';
import FaqPage from './pages/FaqPage';
import {Home} from './pages/Home';
import { JoinUs } from './pages/JoinUs';
import {Projects} from './pages/Projects';
import Team from './pages/Team';
import { Donate } from './pages/Donate';

import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter basename={'/'}>
      <Routes>
        {/* <Route path="/" element={<App />} /> */}
        <Route path="/Home" element={<NavFooterWrapper> <Home/> </NavFooterWrapper>} />
        <Route path="/" element={<NavFooterWrapper> <Home/> </NavFooterWrapper>} />
        <Route path="About" element={<NavFooterWrapper><AboutUs/></NavFooterWrapper>} />
        <Route path="/Team" element={<NavFooterWrapper> <Team/> </NavFooterWrapper>} />
        <Route path="/Projects" element={<> <Projects/> </>} />
        <Route path="/Achievements" element={<NavFooterWrapper> <Achievements/> </NavFooterWrapper>} />
        <Route path="/JoinUs" element={<NavFooterWrapper> <JoinUs/> </NavFooterWrapper>} />
        <Route path="/FAQ" element={<NavFooterWrapper> <FaqPage/> </NavFooterWrapper>} />
        <Route path="*" element={<NavFooterWrapper> <ErrorPage/> </NavFooterWrapper>} />
        <Route path="/Donate" element={<NavFooterWrapper> <Donate/> </NavFooterWrapper>} />
        {/* donate to be added */}


      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
