import React from 'react'
import pres from "../assets/pres.png";

import BlockContent from '@sanity/block-content-to-react';

export const President = ({name,description,image,tenure}) => {
  const serializers = {
    types: {
      block: props => <p>{props.children}</p>,
    },
    marks: {
      strong: props => <strong>{props.children}</strong>,
    },
  };

  const Bio = ({ blockContent }) => {
    return <BlockContent blocks={blockContent} serializers={serializers} />;
  };
    return (
        <div className="flex flex-col md:flex md:flex-row justify-center items-center mx-10 mb-10">
          <div>
            <img className="rounded-xl object-cover w-96 h-96 md:h-72 lg:h-96" src={image.asset.url} alt="" />
          </div>
          <div className=" text-center md:w-[800px] md:px-10 md:ml-10 ">
            <h1 className="text-5xl text-black drop-shadow-lg"> {name} </h1>
            <h2 className="pb-5 pt-2 text-lg text-red-500"> {tenure} </h2>

            <p className="text-lg drop-shadow">
              <Bio blockContent={description} />
            </p>
          </div>
        </div>
    )
}
