import React, { useRef, useState } from 'react'
import { ProjectCard } from './ProjectCard'

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "../styles/ProjectCarousel.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

export const ProjectCarousel = ({ projects }) => {
    const getSidebarTabColor = (tabIndex) => {
        return tabIndex % 2 === 0 ? "yellowland" : "git2";
    };
    return (
        <div className=''>
            <Swiper
                slidesPerView={2.15}
                spaceBetween={4}
                breakpoints={{
                    320: { slidesPerView: 1, spaceBetween: 80 },
                    480: { slidesPerView: 1, spaceBetween: 150 },
                    768: { slidesPerView: 1, spaceBetween: 50 },
                    1024: { slidesPerView: 1.4, spaceBetween: 40 },
                    1400: { slidesPerView: 1.7, spaceBetween: 20 },
                  }}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
            >
                {projects.map((item, index) => (
                    <SwiperSlide>
                            <ProjectCard title={item.title}
                                summary={item.summary}
                                color={getSidebarTabColor(index)} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}
