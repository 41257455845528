import React, { useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
import { Faq } from "../components/Faq";
import { client } from "../lib/client";

const FaqPage = () => {
  const [values, setValues] = useState([]);
  useEffect(() => {
    client
      .fetch(
        `*[_type == "faq"]{
            question,
            answer,
            id
        }
        | order(id asc)`
      )
      .then((data) => {
        setValues(data);
        console.log(data);
      })
      .catch(console.error);
  }, []);
  return (
    <div className="page bg-white">
      <div className="header flex flex-col items-center justify-center text-center bg-yellowland pt-8 pb-5" >
        <h1 className="text-white text-3xl md:text-5xl font-bold drop-shadow-xl pb-3">How can we help you ?</h1>
        <h2 className="max-w-lg font-medium px-5 text-sm md:text-lg">The contribution of an individual can bring transformative changes for many, as it has been greatly said "There could be no definition of a successful life that does not include service to others.

        </h2>
      </div>
      <div className="content w-full lg:px-20 mt-4">
        {values.length > 0 && <Faq values={values} />}
      </div>
      <div className="end text-center text-2xl my-6">
        Didn't find what you were looking for?{" "}
        <HashLink
          to={"/Home/#getintouch"}
          scroll={(el) => el.scrollIntoView({ behavior: "auto", block: "end" })}
          className="text-accentdark">
          Get in touch
        </HashLink>
      </div>
    </div>
  );
};

export default FaqPage;
