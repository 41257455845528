import React from 'react'
import ImpactCard from './ImpactCard'

const OurImpact = ({impactcard}) => {
  return (
    <div className='grid grid-cols-2 place-items-center gap-2  w-fit lg:flex lg:flex-row lg:justify-around md:px-16 md:mb-16 lg:ml-10'>
      {impactcard.map((item) => (
        <ImpactCard key={item.id} {...item} dimension="h-[340px] w-[280px] scale-[0.6] xs:scale-[0.65] sm:scale-75 md:scale-100 -my-10 md:my-4 lg:m-[10px]" />
      ))}
    </div>
  )
}

export default OurImpact