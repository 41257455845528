import React, { useRef } from "react";
import Dropdown from "./Dropdown";
import emailjs from '@emailjs/browser';

export const GetinTouch = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm("service_7bu7t1o", "template_migih5w", form.current, 'dVQztpqZCbUCb8yc8')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    form.current.reset();
  };
  return (
    <section className="  max-w-4xl p-6 mx-auto bg-git2 rounded-md shadow-md w-[365px] xs:w-[380px] md:w-[420px] h-fit scale-[0.85] sm:scale-100">
      <h2 className="  text-[50px] xs:text-[56.75px] font-thin text-black capitalize">
        Get in{" "}
        <span className="text-[50px] xs:text-[56.75px] font-normal text-git">Touch</span>
      </h2>
      <form ref={form} onSubmit={sendEmail}>
        <div className="grid grid-cols-1 gap-6 mt-4">
          <div>
            <input
              placeholder="Name"
              id="name"
              type="text"
              name="name"
              className="block w-full px-4 py-2 mt-2 text-black bg-gray-50 border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring"
            />
          </div>
          <div>
            <input
              placeholder="Email"
              id="emailAddress"
              type="email"
              name="email"
              className="block w-full px-4 py-2 mt-2 text-black bg-gray-50 border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring"
            />
          </div>
          <div>
            <input
              placeholder="Phone number"
              id="phone"
              type="tel"
              name="phone"
              className="block w-full px-4 py-2 mt-2 text-black bg-gray-50 border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring"
            />
          </div>
        </div>
        <div className="mt-2">
          <Dropdown></Dropdown>
        </div>
        <div className="mt-8">
          <textarea
            id="message"
            rows="4"
            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-50 resize-none"
            placeholder="Query/Feedback..."
            name="message"
          ></textarea>
        </div>

        <div className="grid grid-cols-1 gap-6 mt-8" onClick={sendEmail}>
          <button
            className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-700 bg-black rounded-md hover:bg-gray-600  focus:outline-none"
            onClick={sendEmail}
          >
            SEND
          </button>
        </div>
      </form>
    </section>
  );
};
export default GetinTouch;
