import React from "react";
import f from "../assets/404.webp";
import robot from "../assets/robot.webp";

const Fourofour = () => {
  return (
    <body className="bg-white w-screen h-screen flex flex-row py-20 px-10">
      <div className="left pr-20">
        <img src={f} height={280} width={530} alt="404" />
        <div className="font-extralight text-2xl mt-6">
          <h3>Site is down:( yet spirit at peak ?</h3>
          <h3>Don't worry we've got you covered.</h3>
        </div>
          <br/>
        <div className="font-light text-2xl">
          <h3>Reach us @</h3>
          <div className="text-accentdark ml-1">
            <h3>leoclubeternityservice.india@gmail.com</h3>
            {/* //TODO: add mail link and icon */}
            <h3>+91 79825 94793 (Nirjara) </h3>
            {/* add phone icons */}
          </div>
        </div>
      </div>
      <div className="right">
        <img src={robot} alt="robot" height={600} width={550} />
      </div>
    </body>
  );
};

export default Fourofour;
