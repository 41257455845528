import React, { useState } from "react";
import linkdin from "../assets/linkdin.svg";
import plus from "../assets/plus.svg";
import minus from "../assets/minus.svg";
import pres from "../assets/pres.png";

import "../styles/Profile.css";

export const AboutTo = ({ image, description, heading }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  console.log({ isFlipped });

  return (
    <div
      className= {(isFlipped ? "flipped" : "") + " flip-card-toggle scale-[0.9] lg:scale-100"} 
      onClick={() => setIsFlipped(!isFlipped)}
    >
      <div className="flip-card-toggle-inner">
        {/* front */}
        <div className="flip-card-toggle-front">
          <div className="grid place-items-center">
            <div className="group h-fit pb-5 relative border-gray-100/30 flex w-full max-w-xs flex-col self-center overflow-hidden rounded-lg border bg-black shadow-md min-w-fit">
              <div className="z-10 cursor-pointer opacity-10 absolute right-0 hover:opacity-100 ">
                <img
                  className="h-[54px] w-[54px] border-black "
                  src={plus}
                  alt="plus"
                />
              </div>

              <div className="relative mx-3 mt-3 flex h-72 overflow-hidden rounded-sm">
                <img
                  className=" peer absolute z-0 top-0 right-0 w-full object-cover"
                  src={image}
                />
              </div>
            </div>
          </div>
        </div>
        {/* back */}
        <div className="flip-card-toggle-back">
          <div className="grid place-items-center">
            <div className="group relative border-gray-100/30 flex w-full max-w-xs flex-col self-center overflow-hidden rounded-lg border bg-black shadow-md min-w-fit">
              <div className="z-10 cursor-pointer opacity-10 absolute right-0 hover:opacity-100 ">
                <img
                  className="h-[54px] w-[54px] border-black "
                  src={minus}
                  alt="minus"
                />
              </div>

              <div className="relative p-3 flex w-full max-w-[320px] flex-col self-center overflow-hidden rounded-lg  bg-black shadow-md min-w-fit min-h-[350px] max-h-fit">
                <div className="flex justify-center text-5xl pt-10 font-bold">
                  <h1 className="text-yellowland shadow-xl">{heading}</h1>
                </div>
                <span className="text-white text-center text-2xl font-light italic pt-5">{description}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
