import React from 'react'
import {Profile} from './Profile'


const Alumni = ({year,profile}) => {
  return (
    <div className='card drop-shadow-xl bg-white rounded-xl mx-8 md:mx-12 lg:mx-20 items-center wrapper flex flex-col lg:flex-row my-10'>
      <div className="left year w-[18rem] lg:w-[26rem] p-4 justify-center flex">
        <h2 className='text-3xl lg:text-5xl font-semibold'>
          {year}
        </h2>
      </div>
      <div className="right people  w-full">
        <div className='grid grid-rows-3 md:grid-rows-none md:grid-cols-3'>
        {profile.map((item,index) => ( item.year === `${year.substring(0,4)}` &&
          <div className='scale-[0.8] pointer-events-'>
            <Profile key={index} {...item} />
          </div>
        ))}
        </div>
      </div>
    </div>
  )
}

export default Alumni