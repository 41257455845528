import React, { useEffect, useState } from "react";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

export const MainCarousel = ({ images }) => {

    const [currentIndex, setCurrentIndex] = useState(0);

    const handleOnSlideChange = (event) => {
        setCurrentIndex(event.item);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((currentIndex + 1) % images.length);
        }, 3000);
        return () => clearInterval(interval);
    }, [currentIndex,images.length]);

    return (
        <div className="w-full">
            <AliceCarousel
                items={images.map((image, index) => (
                    <div key={index} className="dropShadow-2xl">
                        <img
                            className="w-[90vw] h-[40vh] sm:h-[50vh] lg:h-[70vh] object-cover mx-auto rounded-[10px]"
                            src={image.asset.url}
                            alt={image.asset._id}
                        />
                    </div>
                ))}
                // items={items.map((item) => (
                //     <div key={item.id} className='dropShadow-2xl '>
                //         <img className=" w-[90vw] h-[60vh] object-cover mx-auto rounded-[10px] " src={item.imageUrl} alt={item.title} />
                //     </div>
                // ))}
                activeIndex={currentIndex}
                onSlideChanged={handleOnSlideChange}
                autoPlayInterval={3000}
                autoPlayDirection="ltr"
                autoPlay={true}
                fadeOutAnimation={true}
                mouseDragEnabled={true}
                dotsDisabled={true}
                buttonsDisabled={true}
                showSlideInfo={false}
                disableButtonsControls={true}
                responsive={{
                    0: { items: 1 },
                    768: { items: 1 },
                    1024: { items: 1 },
                }}
            />
        </div>
    )
}
